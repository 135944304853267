<template>
  <div class="questionbox">
    <p class="question">
      {{ data.index + 1 }}、
      {{ data.questionInfo }}
    </p>
    <div>
      <el-radio-group v-model="radiochose" class="radiobox" @input="$emit('change', {value: radiochose, key: data.index})">
        <el-radio
          v-for="(item, j) in data.answerList"
          :key="j"
          :label="j"
        >{{ keys[j] }} {{ item }}</el-radio>
      </el-radio-group>
    </div>
    <div class="questionline"></div>
  </div>
</template>

<script>
import { keys } from "../const/index";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      radiochose: "",
      keys
    };
  },
};
</script>

<style lang="less" scoped>
.questionbox {
  width: 100%;
  text-align: left;
  .question {
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
  }
  .radiobox {
    width: 100%;
    position: relative;
    left: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.questionline {
  width: 100%;
  border-top: 1px dashed #bfbfbf;
  margin-top: 20px;
}
</style>
