<template>
  <div :class="[isPc ? 'infobox' : 'infobox-m']">
    <p :class="[isPc ? 'teachernav' : 'teachernav-m']">收集信息类型</p>
      <div :class="[isPc ? 'line' : 'line-m']">
        <p>信息类型：</p>
        <p>学生信息</p>
      </div>
      <div :class="[isPc ? 'line' : 'line-m']" style="border: none">
        <p>信息收集原因：</p>
        <p>更准确方便的为您安排课程和学习计划</p>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    isPc: {
      type: Boolean | Number,
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../answer.less");
.line-m {
  p {
    width: 50%;
    line-height: 1.5;
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
}
</style>
