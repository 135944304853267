<template>
  <div>
    <div class="anwser">
      <div :class="[isPc ? 'anwser-pc' : 'anwser-m']">
        <div style="position: relative" :class="[ isPc ? 'tabletopcontain' : '']">
          <img v-if="isPc" class="tabletop" src="../assets/img/anwser/bg-pc.png" alt="" />
          <img v-else class="tabletop" src="../assets/img/collect/bg-m.jpg" alt="" />
        </div>

        <div class="teacherbox">
          <img v-if="isPc" class="imghead" src="../assets/img/collect/imghead.png" alt="" />
          <label-msg :isPc="isPc"></label-msg>
        </div>

        <div class="tablebox">
          <div class="main">
            <div>
              <p class="question">请输入您的真实姓名</p>
              <el-input
                placeholder="请输入您的真实姓名"
                v-model="name"
              >
              </el-input>
            </div>
            <div>
              <p class="question">请输入您报名的手机号</p>
              <el-input
                placeholder="请输入您报名的手机号"
                v-model="phoneNum"
                maxlength="11"
                show-word-limit
                @blur="handelCheckName"
              >
              </el-input>
            </div>
            <div>
              <p class="question">资料发放类型</p>
              <el-select v-model="materailType" placeholder="请选择" class="d-flex">
                <el-option
                  v-for="item in optionsMaterailType"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div>
              <p class="question">请具体到省/市/州/县填写，地址一旦提交无法改地址请确认好。</p>
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入您的详细地址"
                v-model="address"
              >
              </el-input>
            </div>

            <div :class="[isPc ? 'surepost-pc' : 'surepost', {'disabled' : submitLoading}]" @click="submit">确认提交</div>
            <img
              :class="[isPc ? 'bottomimg-pc' : 'bottomimg']"
              src="../assets/img/anwser/bottom.png"
              alt=""
            />
          </div>
        </div>

      </div>

      <div v-if="isPc">
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="500px">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div style="padding: 20px; box-sizing: border-box" v-else>
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="100%">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                style="width: 100%"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div v-if="isPc">
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="500px">
            <div class="messageboxcontent" style="display: block">
              <img src="@/assets/img/postsuc.png" alt="postsuc" />
              <p class="sucinfo">您已成功提交表格</p>
              <p class="tip">感谢您的参与</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogPostsuc = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div v-else>
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="100%">
            <div class="messageboxcontent" style="display: block">
              <img src="@/assets/img/postsuc.png" alt="postsuc" />
              <p class="sucinfo">您已成功提交表格</p>
              <p class="tip">感谢您的参与</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogPostsuc = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ruleSelected, ruleRequired } from "../const/index";
import { isMobile } from "../utils/isMobile";
import { checkName, updateAddress } from "../api/index";
import labelMsg from "./components/labelCollectMsg"
export default {
  components: {
    labelMsg,
  },
  data() {
    return {
      ruleSelected,
      ruleRequired,
      optionsMaterailType: ['公务员', '事业编'],
      phoneNum: "",
      name: "",
      materailType: '',
      address: null,
      isPc: 1,
      id: null,
      hasPhoneNum: false,
      submitLoading: false,
      dialogVisible: false,
      errorinfo: "",
      dialogPostsuc: false,
      inputphone: "",
    };
  },
  created() {
    if (isMobile()) {
      this.isPc = 0;
    } else {
      this.isPc = 1;
    }
    this.setPageTitle()
    // this.getData();
  },
  mounted() {
    // this.getfontsize();
    if (!this.isPc) {
      this.setTableBox();
    }
  },
  methods: {
    /* 获取html元素下的css属性 */
    /*
      注意，我们在设置html style的fontsize后，需要给全局 p div等常用标签 加上一个默认的fontsize，以免
    */
    getfontsize() {
      const htmldom = document.querySelector("html");
      htmldom.setAttribute("style", "font-size:10px");
    },
    /*  */
    setTableBox() {
      const dom = document.querySelector(".tablebox");
      const domt = document.querySelector(".teacherbox");
      const base = 750;
      const now = document.documentElement.clientWidth;
      let width = 708.5 * (now / base) + "px";
      domt.setAttribute("style", `width:${width}`);
      dom.setAttribute("style", `width:${width}`);
    },
    async handelCheckName() {
      if (!this.validateNumber(this.phoneNum)) {
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
        return false;
      }
      const errorFn = () => {
        this.hasPhoneNum = false
        this.errorinfo = "不存在，请更换手机号";
        this.dialogVisible = true;
      }
      try {
        const res = await checkName({ phoneNum: this.phoneNum })
        if (res.code == 1) {
          errorFn()
        } else {
          this.hasPhoneNum = true
        }
      } catch (error) {
        errorFn()
      }
    },
    validateNumber(number) {
      var regExp = /^\d{11}$/; // 只能包含十一位数字
      if (regExp.test(number)) {
        return true;
      } else {
        return false;
      }
    },
    async submit() {
      if (this.submitLoading) return
      if (!this.hasPhoneNum) {
        this.errorinfo = "不存在，请更换手机号";
        this.dialogVisible = true;
        return false;
      }
      if (!this.name) {
        this.errorinfo = "请输入您的真实姓名";
        this.dialogVisible = true;
        return false;
      }
      if (!this.validateNumber(this.phoneNum)) {
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
        return false;
      }
      if (!this.materailType) {
        this.errorinfo = "请选择资料发放类";
        this.dialogVisible = true;
        return false;
      }
      if (!this.address) {
        this.errorinfo = "请输入您的详细地址";
        this.dialogVisible = true;
        return false;
      }
      this.submitLoading = true
      const form = {
        name: this.name,
        address: this.address,
        phoneNum: this.phoneNum,
        materailType: this.materailType
      };
      let res = null;
      try {
        res = await updateAddress(form);
        // if (res.code == 0 || res.code == 1) {
        if (res.code == 0) {
          this.dialogPostsuc = true;
          // this.$message.success("提交成功");
        } else {
          this.$message.success(res.errMsg);
        }
      } catch (error) {
        this.$message.error("提交失败");
      } finally {
        this.submitLoading = false
      }
    },
    /* 号码校验 */
    vaildPhone() {
      const tel = Number(this.inputphone);
      const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      const result = re.test(tel);
      if (!result) {
        console.log("请输入正确的电话格式");
        return false;
      } else {
        return true;
      }
    },
    /* 设置页面标题 */
    setPageTitle() {
      document.title = '快递地址信息收集';
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./answer.less");
.numberinuput {
  display: flex;
  align-items: center;
  margin-top: 38px;
  font-size: 20px;
  font-weight: 600;
}
.mg-t2 {
  margin-top: 20px;
}
.text-tip {
  text-align: left;
}
.bottomimg {
  right: 0;
  bottom: 0;
}
</style>
