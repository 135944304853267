<template>
  <div :class="[isPc ? 'infobox' : 'infobox-m']">
    <p :class="[isPc ? 'teachernav' : 'teachernav-m']">收集信息类型</p>
      <div :class="[isPc ? 'line' : 'line-m']">
        <p>信息类型：</p>
        <p>姓名 地址</p>
      </div>
      <div :class="[isPc ? 'line' : 'line-m']" style="border: none">
        <p>信息收集原因：</p>
        <p>方便发送资料快递给学员</p>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    isPc: {
      type: Boolean | Number,
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../answer.less");
</style>
