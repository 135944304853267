<template>
  <div>
    <div class="anwser student-wrapper">
      <div :class="[isPc ? 'anwser-pc' : 'anwser-m']">
        <div style="position: relative" :class="[ isPc ? 'tabletopcontain' : '']">
          <img v-if="isPc" class="tabletop" src="../assets/img/titleList/bg-pc.png" alt="" />
          <img v-else class="tabletop" src="../assets/img/titleList/bg-m.png" alt="" />
        </div>
        <div class="tablebox">
          <div>
            <div class="title-bg">请同学认真完成下面题目内容</div>
            <h3 class="title">题目信息</h3>
            <div class="d-flex item">
              <span>测试内容:</span>
              <span class="text-info">{{ exam.examName }}</span>
            </div>
            <!-- <div class="d-flex item">
              <span>测试类型:</span>
              <span  class="text-info">{{ questionTypeOptions[exam.questionType - 1] }}</span>
            </div> -->
            <div class="d-flex item">
              <span>题目数量:</span>
              <span class="text-info">{{ exam.questionNum }}</span>
            </div>
            <div class="item" :class="!isPc ? 'text-left' : 'd-flex'">
              <span>题目内容:</span>
              <span v-if="isPc" class="text-info">{{ exam.examNote }}</span>
              <p v-else class="text-info">{{ exam.examNote}}</p>
            </div>
            <h3 class="title">开始作答</h3>
          </div>
          <el-form v-model="form"  label-position="top">
            <div class="main">
              <div v-for="(item, index) in questionList" :key="index">
                <!-- <template v-for="(subItem, sI) in subTitleList" class="line">
                  <h5 v-if="subItem.positionIndex == index" :key="sI + 'subtitle'" class="sub-title text-left">{{ subItem.title }}</h5>
                </template> -->
                <el-form-item :prop="item.id + ''">
                  <p slot="label" class="question"><span v-if="formRequiredArr.includes(item.id)" class="required">*</span>
                    {{item.key}}. {{item.questionInfo}}
                  </p>
                  <img v-if="item.questionImg" :src="item.questionImg" alt="" class="img-fluid" preview="4">
                  <!-- <div v-for="imgUrl in item.titleImgList" :key="imgUrl">
                  </div> -->
                  <el-radio-group v-model="form[item.id]" class="d-flex" :disabled="isAnswer">
                    <el-row>
                      <el-col v-for="oI in item.answerList" :key="oI.key + item.id" :span="24" class="mg-b1" :class="isAnswer && computerClass(item, oI)">
                        <el-radio :label="oI.key" >{{ oI.key }}. {{ oI.value }}</el-radio>
                      </el-col>
                    </el-row>
                  </el-radio-group>
                </el-form-item>
                <div v-if="isAnswer" class="analysis-wrap">
                  <h5>答案解析：</h5>
                  <div v-if="item.analysis" class="text-content text-pre text-df text-black">
                    {{item.analysis}}
                  </div>
                  <div v-if="item.analysisImg" class="margin-top">
                    <img :src="item.analysisImg" class="img-fluid"></img>
                  </div>
                </div>
              </div>
              <el-form-item prop="phoneNum">
                <span slot="label" class="question"><span v-if="formRequiredArr.includes('phoneNum')" class="required">*</span>报名手机号（请输入否则无法计算结果）</span>
                <el-input
                  placeholder="请输入报名手机号"
                  v-model="form.phoneNum"
                  maxlength="11"
                  show-word-limit
                  @blur="handelCheckName"
                >
                </el-input>
              </el-form-item>
              <div v-if="!isAnswer" :class="[isPc ? 'surepost-pc' : 'surepost', {'disabled' : submitLoading}]" @click="submit">确认提交</div>
              <img
                :class="[isPc ? 'bottomimg-pc' : 'bottomimg']"
                src="../assets/img/anwser/bottom.png"
                alt=""
              />
            </div>
          </el-form>
        </div>

      </div>

      <div v-if="isPc">
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="500px">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div v-else style="padding: 20px; box-sizing: border-box">
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="100%">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                style="width: 100%"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div v-if="isPc">
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="500px">
            <div  slot="title" class="messageboxtitle">
              <img src="@/assets/img/titleList/icon.png" alt="postsuc" />
              <p class="title">您的做题数据</p>
            </div>
            <div v-for="item in successList" :key="item.label + 'pc'" class="item">
              <span>{{ item.label }}: </span>
              <span>{{ item.value }}</span>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="showAnalysis"
                >查看解析</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div v-else>
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="100%">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/titleList/icon.png" alt="postsuc" />
              <p class="title">您的做题数据</p>
            </div>
            <div v-for="item in successList" :key="item.label + 'm'" class="item">
              <span>{{ item.label }}: </span>
              <span>{{ item.value }}</span>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="showAnalysis"
                >查看解析</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ruleSelected, ruleRequired } from "../const/index";
import { isMobile } from "../utils/isMobile";
import { checkName, submitAnswer, getQuestionList } from "../api/index";
import labelMsg from "./components/labelCollectStudentMsg"
export default {
  components: {
    labelMsg,
  },
  data() {
    return {
      ruleSelected,
      ruleRequired,
      questionTypeOptions: ['行政能力测试', '申论', '职业能力测验', '综合能力测试', '公共基础知识'],
      form: {
        phoneNum: '',
      },
      formLabel: {
        phoneNum: '电话',
      },
      formRequiredArr: ['phoneNum'],
      isPc: 1,
      hasPhoneNum: false,
      submitLoading: false,
      dialogVisible: false,
      errorinfo: "",
      dialogPostsuc: false,
      questionList: [],
      exam: {
        examName: '',
        questionType: '',
        questionNum: '',
        examNote: '',
      },
      successList: [
        {
          label: '题目数量',
          value: 0,
          key: 'questionNum'
        },
        {
          label: '正确数量',
          value: 0,
          key: 'correctNum'
        },
        {
          label: '回答数量',
          value: 0,
          key: 'answerNum'
        },
        {
          label: '正确率',
          value: 0,
          key: 'correctRate'
        }
      ],
      isAnswer: false
    };
  },
  created() {
    if (isMobile()) {
      this.isPc = 0;
    } else {
      this.isPc = 1;
    }
    // this.setPageTitle()
    // this.optionsCityTree = convertAddressTree()
    this.getQuestionList();
  },
  mounted() {
    // this.getfontsize();
    if (!this.isPc) {
      this.setTableBox();
    }
  },
  methods: {
    showAnalysis() {
      this.dialogPostsuc = false;
      this.isAnswer = true
    },
    computerClass(item, oI) {
      if (oI.key == item.rightAnswer) {
          return 'text-green'
        }
      if (!item.isRight) {
        if (item.resultAnswer == oI.key) {
          return 'text-red'
        }
      }
    },
    formatQuestion(data) {
      let arr = []
      arr = data.map((item, i) => {
        if ([1, 2].includes(item.questionType)) {
          try{
            item.answerList = JSON.parse(item.answerList)
          }catch(e){
            //TODO handle the exception
            item.answerList = item.answerList
          }
          item.answerList = Object.keys(item.answerList).map(name => {
            const type = this.judageImg(item.answerList[name]) ? 2 : 1
            if (!item.activeOptionsType) {
              item.activeOptionsType = type
            }
            return {
              key: name,
              value: item.answerList[name]
            }
          })
          item.right = item.rightAnswer.split(',')
          item.result = [];
          item.key = ++i;
        }
        return item
      })
      return arr
    },
    judageImg(str) {
      const pattern = /^https.*.png$/
      const result = str.match(pattern)
      return result
    },
    async getQuestionList() {
      try {
        this.isAnswer = false
        const { query } = this.$route
        if (query) {
          this.form.phoneNum = query.phoneNum;
        }
        // 获取 params
        const codeInfo = this.$route.params.codeInfo
        const res = await getQuestionList({
          codeInfo
        });
        if (res) {
          const { questionList, ...otherData } = res
          this.exam = otherData
          document.title = this.exam.examName
          this.questionList = this.formatQuestion(questionList)
          console.log(this.questionList);
          this.$previewRefresh()
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* 获取html元素下的css属性 */
    /*
      注意，我们在设置html style的fontsize后，需要给全局 p div等常用标签 加上一个默认的fontsize，以免
    */
    getfontsize() {
      const htmldom = document.querySelector("html");
      htmldom.setAttribute("style", "font-size:10px");
    },
    /*  */
    setTableBox() {
      const dom = document.querySelector(".tablebox");
      // const domt = document.querySelector(".teacherbox");
      const base = 750;
      const now = document.documentElement.clientWidth;
      let width = 708.5 * (now / base) + "px";
      // domt.setAttribute("style", `width:${width}`);
      dom.setAttribute("style", `width:${width}`);
    },
    async handelCheckName() {
      if (!this.validateNumber(this.form.phoneNum)) {
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
        return false;
      }
      const errorFn = () => {
        this.hasPhoneNum = false
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
      }
      try {
        const res = await checkName({ phoneNum: this.form.phoneNum })
        if (res.code == 1) {
          errorFn()
        } else {
          this.hasPhoneNum = true
        }
      } catch (error) {
        errorFn()
      }
    },
    handleChangeSignCity(val) {
      console.log(val);
    },
    handleChangeEduType(val) {
      console.log(val);
    },
    validateNumber(number) {
      var regExp = /^\d{11}$/; // 只能包含十一位数字
      if (regExp.test(number)) {
        return true;
      } else {
        return false;
      }
    },
    formatAnswerData() {
      const answerList = []
      this.questionList.map(item => {
        answerList.push({
          questionId: item.id,
          answerInfo: this.form[item.id]
        })
      })
      return answerList
    },
    async submit() {
      // console.log(this.form);
      if (this.submitLoading) return
      if (!this.hasPhoneNum) {
        if (this.form.phoneNum) {
          await this.handelCheckName()
          if (!this.hasPhoneNum) return
        } else {
          this.errorinfo = "请输入您报名的手机号";
          this.dialogVisible = true;
          return false;
        }
      }
      const answerList = this.formatAnswerData()
      const Form = {
        phoneNum: this.form.phoneNum,
        codeInfo: this.exam.codeInfo,
        examId: this.exam.id,
        examName: this.exam.examName,
        answerList,
      }
      const that = this
      const errFn = (name, text) => {
        console.log(that.formLabel[name], name);
        this.errorinfo = text || `请输入${that.formLabel[name]}`;
        this.dialogVisible = true;
      }
      for(let key of this.formRequiredArr) {
        if (!this.form[key]) {
          errFn(key)
          return false
        }
      }
      this.submitLoading = true
      let res = null;
      console.log(Form);
      try {
        // wxId=xx&chatWxId=xx 获取 url 参数
        const { query } = this.$route
        if (query) {
          Form.wxId = query.wxId;
          Form.chatWxId = query.chatWxId;
        }
        res = await submitAnswer(Form);
        console.log(res);
        // if (res.code == 0 || res.code == 1) {
        if (res.code == 0) {
          this.dialogPostsuc = true;
          this.successList.forEach((item, i) => {
            if (item.key == 'correctRate') {
              // correctNum / questionNum 百分比
              const rate = res.questionNum ? Math.floor((res.correctNum / res.questionNum) * 100) : 0
              this.$set(this.successList[i], 'value', rate + '%')
            } else {
              this.$set(this.successList[i], 'value', res[item.key])
            }
          })
          res.answerList.forEach(item => {
            const i = this.questionList.findIndex(q => q.id == item.questionId)
            if (i > -1) {
              this.questionList[i].isRight = item.isRight
              this.questionList[i].resultAnswer = item.answerInfo
            }
          })
          // this.$message.success("提交成功");
        } else {
          // this.$message.success(res.errMsg);
          this.dialogVisible = true;
          this.errorinfo = res.errMsg
        }
      } catch (error) {
        console.log(error);
        this.$message.error("提交失败");
      } finally {
        this.submitLoading = false
      }
    },
    /* 号码校验 */
    vaildPhone() {
      const tel = Number(this.inputphone);
      const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      const result = re.test(tel);
      if (!result) {
        console.log("请输入正确的电话格式");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./exam.less");
.mg-b1 {
  margin-bottom: 10px;
}
.anwser-m .teacherbox {
  border-top: 0;
}
.numberinuput {
  display: flex;
  align-items: center;
  margin-top: 38px;
  font-size: 20px;
  font-weight: 600;
}
.mg-t2 {
  margin-top: 20px;
}
.text-tip {
  text-align: left;
}
.bottomimg {
  right: 0;
  bottom: 0;
}
.required {
  color: red;
  margin-right: 3px;
}
p.question {
  white-space: pre-line;
  line-height: 1.5;
  color: #666;
}
.sub-title {
  margin: 0;
  line-height: 1.5;
  font-size: 15px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>
<style lang="less">
.student-wrapper {
  .el-form-item__label {
    width: 100%;
  }
  .el-radio {
    display: flex;
  }
  .el-radio__input, .el-radio__label {
    line-height: 1.5;
  }
  .el-radio__inner {
    margin-top: 4px;
  }
  .el-radio__label {
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 15px;
  }
  .text-green {
    .el-radio__label {
      color: green!important;
    }
  }
  .text-red {
    .el-radio__label {
      color: red!important;
    }
  }
}

</style>
