import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// mormalize.css
import "./assets/normalize.css";
// color.css
import "./assets/color.css";

Vue.use(ElementUI);

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

Vue.config.productionTip = false;

const setDocumentTitle = function(title) {
  document.title = title;
  if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
      var i = document.createElement('iframe');
      i.src = '/favicon.ico';
      i.style.display = 'none';
      i.onload = function() {
          setTimeout(function(){
              i.remove();
          }, 9)
      }
      document.body.appendChild(i);
  }
}
// 监听router
router.beforeEach((to, from, next) => {
  // meta title
  setDocumentTitle(to.meta.title);
  next();
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
