import Vue from "vue";
import VueRouter from "vue-router";
import answer from "@/views/answer.vue";
import collect from "@/views/collect.vue";
import collectStudent from "@/views/collect-student.vue";
import collectStudentTest from "@/views/collect-student-test.vue";
import informationDetail from "@/views/information-detail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/data/collect",
    name: "CollectData",
    component: collect,
    meta: {
      title: "学员信息收集",
    }
  },
  {
    path: "/student-data/collect",
    name: "CollectStudentData",
    component: collectStudent,
    meta: {
      title: "新生入学登记",
    }
  },
  {
    path: '/exam/:codeInfo?',
    name: 'CollectStudentTest',
    component: collectStudentTest,
    meta: {
      title: '学生作业'
    }
  },
  {
    path: "/information/detail/:id?",
    name: "InformationDetail",
    component: informationDetail,
    meta: {
      title: "信息详情",
    }
  },
  {
    path: "/:id?",
    name: "Evaluation",
    component: answer,
    meta: {
      title: "班主任调查问卷",
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
