import axios from 'axios'
import { Message } from 'element-ui'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  // timeout: 5000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    // 增加延迟，相同请求不得在短时间内重复发送
    const url = response.config.url
    return response.data
  },
  error => {
    // console.log('err' + error) // for debug
    // console.log('err' + error.response) // for debug
    const url = error.config && error.config.url // for debug
    if (error.response.status == 403) {
      Message.error({ message: '登录超时，请重新登录!' })
    } else if (error.response.status == 401) {
      Message.error({ message: '您无权进行此次操作!' })
    } else if (error.response.status == 402) {
      Message.error({ message: '接口访问过于频繁请稍后重试!' })
    } else if (error.message.indexOf('请求被中断') === -1) {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
