<template>
  <div :class="[isPc ? 'infobox' : 'infobox-m']">
    <p :class="[isPc ? 'teachernav' : 'teachernav-m']">受评人信息</p>
    <template v-if="serveyType == 1">
      <div :class="[isPc ? 'line' : 'line-m']">
        <p>授课老师：</p>
        <p>{{ msg.teacherInfo }}</p>
      </div>

      <div :class="[isPc ? 'line' : 'line-m']">
        <p>授课天数：</p>
        <p>{{ msg.days }}天</p>
      </div>

      <div :class="[isPc ? 'line' : 'line-m']" style="border: none">
        <p>课程内容：</p>
        <p>{{ msg.courseInfo }}</p>
      </div>
    </template>
    <template v-if="serveyType == 2">
      <div :class="[isPc ? 'line' : 'line-m']" style="border: none">
        <p>受评对象：</p>
        <p>班主任</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isPc: {
      type: Boolean | Number,
      default: true,
    },
    msg: {
      type: Object,
      default: () => {
        return {};
      },
    },
    serveyType: {
      type: Number | String,
      default: 1,
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../answer.less");
</style>
