export const useranswertable = [
  {
    question: "1.遵守教学纪律，无迟到、提前下课，上课拨接手机等现象。",
    answerlist: ["A.很满意", "B.满意", "C.一般", "D.不满意"],
  },
  {
    question:
      "2.老师在课堂上是否有热情，有一种想把知识交给你强烈的愿望与激情，没有仅仅是完成任务式的教学。",
    answerlist: ["A.很热情", "B.热情", "C.一般", "D.不耐烦"],
  },
  {
    question: "3.老师是否备课充分，熟悉所教内容，无知识性错误？",
    answerlist: ["A.很充分", "B.充分", "C.一般", "D.完全没有备课"],
  },
  {
    question: "4.老师是否讲课思路清晰，知识、概念的阐述是否清晰准确？",
    answerlist: ["A.很清晰", "B.清晰", "C.一般", "D.不清晰"],
  },
  {
    question: "5.老师上课课程信息量是否足够，课程内容充实？",
    answerlist: ["A.很满意", "B.满意", "C.一般", "D.不满意"],
  },
  {
    question: "6.老师对重点难点的讲解是否有深度？",
    answerlist: ["A.很满意", "B.满意", "C.一般", "D.不满意"],
  },
  {
    question: "7.老师在课堂上是否乐于提问，关注你的消化、吸收情况？",
    answerlist: ["A.很关注", "B.关注", "C.偶尔关注", "D.完全不关注"],
  },
  {
    question:
      "8.你对该老师所讲的课程内容消化、吸收情况如何，也就是学习效果如何。",
    answerlist: ["A.很满意", "B.满意", "C.一般", "D.不满意"],
  },
  {
    question:
      "9.你认为本课程教师在教学中能否激发学生的学习兴趣,发挥学生的学习主动性，培养学生独立思考和勇于创新的精神？也就是教师的激发能力如何？",
    answerlist: [
      "A.很符合以上",
      "B.符合以上",
      "C.偶尔符合以上",
      "D.不符合以上",
    ],
  },
  {
    question: "10.你对该授课老师的总体评价是",
    answerlist: ["A.很满意", "B.满意", "C.一般", "D.不满意"],
  },
];

export const ruleSelected = {
  required: true,
  message: "必选",
  trigger: ["blur", "change"],
};
export const ruleRequired = {
  required: true,
  message: "必填",
  trigger: ["blur", "change"],
};
export const keys = ["A", "B", "C", "D"];

export const provinces = [
  "北京市",
  "上海市",
  "天津市",
  "重庆市",
  "河北省",
  "山西省",
  "辽宁省",
  "吉林省",
  "黑龙江省",
  "江苏省",
  "浙江省",
  "安徽省",
  "福建省",
  "江西省",
  "山东省",
  "河南省",
  "湖北省",
  "湖南省",
  "广东省",
  "海南省",
  "四川省",
  "贵州省",
  "云南省",
  "陕西省",
  "甘肃省",
  "青海省",
  "台湾省",
  "内蒙古自治区",
  "广西壮族自治区",
  "西藏自治区",
  "宁夏回族自治区",
  "新疆维吾尔自治区",
  "香港特别行政区",
  "澳门特别行政区",
];

export const cityArr = [
  ["北京"],
  ["天津"],
  ["上海"],
  ["重庆"],
  [
    "武汉",
    "黄石",
    "十堰",
    "宜昌",
    "襄樊",
    "鄂州",
    "荆门",
    "孝感",
    "荆州",
    "黄冈",
    "咸宁",
    "随州",
    "恩施土家族苗族自治州",
    "仙桃",
    "潜江",
    "天门",
    "神农架林区",
  ],
  [
    "石家庄",
    "唐山",
    "秦皇岛",
    "邯郸",
    "邢台",
    "保定",
    "张家口",
    "承德",
    "沧州",
    "廊坊",
    "衡水",
  ],
  [
    "安阳",
    "鹤壁",
    "济源",
    "焦作",
    "开封",
    "洛阳",
    "南阳",
    "平顶山",
    "濮阳",
    "三门峡",
    "商丘",
    "漯河",
    "新乡",
    "信阳",
    "许昌",
    "郑州",
    "周口",
    "驻马店",
  ],
  ["福州", "厦门", "莆田", "三明", "泉州", "漳州", "南平", "龙岩", "宁德"],
  [
    "广州",
    "韶关",
    "珠海",
    "深圳",
    "汕头",
    "佛山",
    "肇庆",
    "揭阳",
    "中山",
    "云浮",
    "江门",
    "湛江",
    "茂名",
    "惠州",
    "东莞",
    "阳江",
    "清远",
    "梅州",
    "汕尾",
    "河源",
    "潮州",
  ],
  [
    "海口",
    "三亚",
    "五指山",
    "琼海",
    "儋州",
    "文昌",
    "万宁",
    "东方",
    "定安县",
    "屯昌县",
    "澄迈县",
    "临高县",
    "白沙黎族自治县",
    "昌江黎族自治县",
    "乐东黎族自治县",
    "陵水黎族自治县",
    "保亭黎族苗族自治县",
    "琼中黎族苗族自治县",
  ],
  ["台北", "高雄", "基隆", "台中", "台南", "新竹", "嘉义"],
  [
    "成都",
    "自贡",
    "攀枝花",
    "泸州",
    "德阳",
    "绵阳",
    "广元",
    "遂宁",
    "内江",
    "乐山",
    "南充",
    "眉山",
    "宜宾",
    "广安",
    "达州",
    "雅安",
    "巴中",
    "资阳",
    "阿坝藏族羌族自治州",
    "甘孜藏族自治州",
    "凉山彝族自治州",
  ],
  [
    "长沙",
    "株洲",
    "湘潭",
    "衡阳",
    "邵阳",
    "岳阳",
    "常德",
    "张家界",
    "益阳",
    "郴州",
    "永州",
    "怀化",
    "娄底",
    "湘西土家族苗族自治州",
  ],
  [
    "合肥",
    "芜湖",
    "蚌埠",
    "淮南",
    "马鞍山",
    "淮北",
    "铜陵",
    "安庆",
    "黄山",
    "滁州",
    "阜阳",
    "宿州",
    "巢湖",
    "六安",
    "亳州",
    "池州",
    "宣城",
  ],
  [
    "南京",
    "无锡",
    "徐州",
    "常州",
    "苏州",
    "南通",
    "连云港",
    "淮安",
    "盐城",
    "扬州",
    "镇江",
    "泰州",
    "宿迁",
  ],
  [
    "昆明",
    " 曲靖",
    "玉溪",
    "保山",
    "昭通",
    "丽江",
    "思茅",
    "临沧",
    "楚雄彝族自治州",
    "红河哈尼族彝族自治州",
    "文山壮族苗族自治州",
    "西双版纳傣族自治州",
    "大理白族自治州",
    "德宏傣族景颇族自治州",
    "怒江傈僳族自治州",
    "迪庆藏族自治州",
  ],
  [
    "乌鲁木齐",
    "克拉玛依",
    "吐鲁番地区",
    "哈密地区",
    "昌吉回族自治州 ",
    "博尔塔拉蒙古自治州 ",
    "巴音郭楞蒙古自治州 ",
    "阿克苏地区",
    "克孜勒苏柯尔克孜自治州 ",
    "喀什地区",
    "和田地区",
    "伊犁哈萨克自治州",
    "塔城地区",
    "阿勒泰地区",
    "石河子",
    "阿拉尔",
    "图木舒克",
    "五家渠",
  ],
  [
    "呼和浩特",
    "包头",
    "乌海",
    "赤峰",
    "通辽",
    "鄂尔多斯",
    "呼伦贝尔",
    "巴彦淖尔",
    "乌兰察布",
    "兴安盟",
    "锡林郭勒盟",
    "阿拉善盟",
  ],
  [
    "兰州",
    "嘉峪关",
    "金昌",
    "白银",
    "天水",
    "武威",
    "张掖",
    "平凉",
    "酒泉",
    "庆阳",
    "定西",
    "陇南",
    "临夏回族自治州",
    "甘南藏族自治州",
  ],
  [
    "南宁",
    "柳州",
    "桂林",
    "梧州",
    "北海",
    "防城港",
    "钦州",
    "贵港",
    "玉林",
    "百色",
    "贺州",
    "河池",
    "来宾",
    "崇左",
  ],
  ["银川", "石嘴山", "吴忠", "固原", "中卫"],
  [
    "中西区",
    "湾仔区",
    "东区",
    "南区",
    "油尖旺区",
    "深水埗区",
    "九龙城区",
    "黄大仙区",
    "观塘区",
    "荃湾区",
    "葵青区",
    "沙田区",
    "西贡区",
    "大埔区",
    "北区",
    "元朗区",
    "屯门区",
    "离岛区",
  ],
  ["澳门"],
  [
    "杭州",
    "宁波",
    "温州",
    "嘉兴",
    "湖州",
    "绍兴",
    "金华",
    "衢州",
    "舟山",
    "台州",
    "丽水",
  ],
  [
    "沈阳",
    "大连",
    "鞍山",
    "抚顺",
    "本溪",
    "丹东",
    "锦州",
    "营口",
    "阜新",
    "辽阳",
    "盘锦",
    "铁岭",
    "朝阳",
    "葫芦岛",
  ],
  [
    "哈尔滨",
    "齐齐哈尔",
    "鸡西",
    "鹤岗",
    "双鸭山",
    "大庆",
    "伊春",
    "佳木斯",
    "七台河",
    "牡丹江",
    "黑河",
    "绥化",
    "大兴安岭地区",
  ],
  [
    "济南",
    "青岛",
    "淄博",
    "枣庄",
    "东营",
    "烟台",
    "潍坊",
    "济宁",
    "泰安",
    "威海",
    "日照",
    "莱芜",
    "临沂",
    "德州",
    "聊城",
    "滨州",
    "菏泽",
  ],
  [
    "南昌",
    "景德镇",
    "萍乡",
    "九江",
    "新余",
    "鹰潭",
    "赣州",
    "吉安",
    "宜春",
    "抚州",
    "上饶",
  ],
  [
    "太原",
    "大同",
    "阳泉",
    "长治",
    "晋城",
    "朔州",
    "晋中",
    "运城",
    "忻州",
    "临汾",
    "吕梁",
  ],
  [
    "西安",
    "铜川",
    "宝鸡",
    "咸阳",
    "渭南",
    "延安",
    "汉中",
    "榆林",
    "安康",
    "商洛",
  ],
  [
    "长春",
    "吉林",
    "四平",
    "辽源",
    "通化",
    "白山",
    "松原",
    "白城",
    "延边朝鲜族自治州",
  ],
  [
    "贵阳",
    "六盘水",
    "遵义",
    "安顺",
    "铜仁地区",
    "黔西南布依族苗族自治州",
    "毕节地区",
    "黔东南苗族侗族自治州",
    "黔南布依族苗族自治州",
  ],
  [
    "西宁",
    "海东地区",
    "海北藏族自治州",
    "黄南藏族自治州",
    "海南藏族自治州",
    "果洛藏族自治州",
    "玉树藏族自治州",
    "海西蒙古族藏族自治州",
  ],
  [
    "拉萨",
    "昌都地区",
    "山南地区",
    "日喀则地",
    "那曲地区",
    "阿里地区",
    "林芝地区",
  ],
];
export const provinceArr = [
  "北京市",
  "天津市",
  "上海市",
  "重庆市",
  "湖北省",
  "河北省",
  "河南省",
  "福建省",
  "广东省",
  "海南省",
  "台湾省",
  "四川省",
  "湖南省",
  "安徽省",
  "江苏省",
  "云南省",
  "新疆维吾尔自治区",
  "内蒙古自治区",
  "甘肃",
  "广西壮族自治区",
  "宁夏回族自治区",
  "香港特别行政区",
  "澳门特别行政区",
  "浙江省",
  "辽宁省",
  "黑龙江省",
  "山东省",
  "江西省",
  "山西省",
  "陕西省",
  "吉林省",
  "贵州省",
  "青海省",
  "西藏自治区",
];

function convertOptionItem(item, level, parent) {
  const obj = {
    value: item,
    label: item,
    level,
    parent,
    id: `${item}-${level}`,
  };
  if (level === 0) {
    obj.children = [];
  }
  return obj;
}
function convertGroup(items, level, parent) {
  return items.map((item, i) => {
    const obj = convertOptionItem(item, level, parent);
    if (level === 0) {
      obj.children = convertGroup(cityArr[i], 1, item);
    }
    return obj;
  });
}
export function convertAddressTree(val) {
  console.log(convertGroup(provinceArr, 0));
  if (val) {
    const country = [
      {
        value: val,
        label: val,
        level: -1,
        parent: null,
        id: `${val}--1`,
        children: convertGroup(provinceArr, 0),
      },
    ];
    //   provinceArr.splice(0, 1)
    return country;
  } else {
    return convertGroup(provinceArr, 0);
  }
  // console.log(convertGroup(provinceArr, 0))
  // return convertGroup(provinceArr, 0)
}

export const majorList = [
  {
    id: "1",
    className: "管理科学与工程类",
    class: [
      {
        name: "管理科学",
      },
      {
        name: "信息管理和信息系统",
      },
      {
        name: "工业工程",
      },
      {
        name: "工程管理",
      },
      {
        name: "管理科学",
      },
      {
        name: "产品质量工程",
      },
    ],
  },
  {
    id: "2",
    className: "工商管理类",
    class: [
      {
        name: "工商管理",
      },
      {
        name: "市场营销",
      },
      {
        name: "商品学",
      },
      {
        name: "工程管理",
      },
      {
        name: "会计学",
      },
      {
        name: "审计学",
      },
      {
        name: "涉外会计",
      },
      {
        name: "会计信息系统",
      },
      {
        name: "财务管理",
      },
      {
        name: "财政金融",
      },
      {
        name: "资产评估",
      },
      {
        name: "企业管理",
      },
      {
        name: "商务策划管理",
      },
      {
        name: "国际商务",
      },
      {
        name: "电子商务",
      },
      {
        name: "房地产经营管理",
      },
      {
        name: "物业管理",
      },
      {
        name: "物流管理",
      },
      {
        name: "旅游管理",
      },
      {
        name: "酒店管理",
      },
      {
        name: "连锁经营管理",
      },
      {
        name: "人力资源管理",
      },
    ],
  },
  {
    id: "3",
    className: "公共管理类",
    class: [
      {
        name: "行政管理",
      },
      {
        name: "公共关系学",
      },
      {
        name: "文秘",
      },
      {
        name: "公共事业管理",
      },
      {
        name: "公共政策学",
      },
      {
        name: "国防教育与管理",
      },
      {
        name: "劳动关系",
      },
      {
        name: "劳动与社会保障",
      },
      {
        name: "城市规划与管理",
      },
      {
        name: "土地资源管理",
      },
      {
        name: "农业经济管理",
      },
      {
        name: "食品经济管理",
      },
      {
        name: "会展经济与管理",
      },
      {
        name: "文化产业管理",
      },
      {
        name: "体育产业管理",
      },
      {
        name: "房地产经营管理",
      },
      {
        name: "航运管理",
      },
    ],
  },
  {
    id: "4",
    className: "图书档案学类",
    class: [
      {
        name: "图书馆学和档案学",
      },
      {
        name: "信息资源管理",
      },
    ],
  },
  {
    id: "5",
    className: "电子信息科学类",
    class: [
      {
        name: "电子信息科学与技术",
      },
      {
        name: "光信息科学与技术",
      },
      {
        name: "光电子技术科学",
      },
      {
        name: "微电子学",
      },
      {
        name: "信息科学技术",
      },
      {
        name: "信息安全",
      },
      {
        name: "科技防卫",
      },
    ],
  },
  {
    id: "6",
    className: "机械类",
    class: [
      {
        name: "机械设计制造及其自动化",
      },
      {
        name: "机械电子工程/机电一体化",
      },
      {
        name: "机械工程及自动化",
      },
      {
        name: "机械制造工艺与设备",
      },
      {
        name: "制造工程",
      },
      {
        name: "制造自动化与测控技术",
      },
      {
        name: "材料成型及控制工程",
      },
      {
        name: "工业设计",
      },
      {
        name: "过程装备与控制工程",
      },
      {
        name: "模具设计与制造",
      },
      {
        name: "微机电系统工程",
      },
      {
        name: "车辆工程",
      },
      {
        name: "汽车服务工程",
      },
    ],
  },
  {
    id: "7",
    className: "仪器仪表类",
    class: [
      {
        name: "测控技术与仪器",
      },
      {
        name: "能源动力类",
      },
      {
        name: "热能与动力工程",
      },
      {
        name: "电力系统及自动化",
      },
      {
        name: "制冷与低温技术",
      },
    ],
  },
  {
    id: "8",
    className: "材料类",
    class: [
      {
        name: "材料科学与工程",
      },
      {
        name: "材料物理",
      },
      {
        name: "材料化学",
      },
      {
        name: "冶金工程",
      },
      {
        name: "金属材料工程",
      },
      {
        name: "无机非金属材料工程",
      },
      {
        name: "高分子材料与工程",
      },
      {
        name: "电子封装技术",
      },
    ],
  },
  {
    id: "9",
    className: "轻工纺织食品类",
    class: [
      {
        name: "食品科学与工程",
      },
      {
        name: "轻化工程",
      },
      {
        name: "包装工程",
      },
      {
        name: "印刷工程",
      },
      {
        name: "服装设计与工程",
      },
      {
        name: "纺织工程",
      },
    ],
  },
  {
    id: "10",
    className: "土建类",
    class: [
      {
        name: "土木工程",
      },
      {
        name: "道路与桥梁",
      },
      {
        name: "建筑学",
      },
      {
        name: "建筑工程",
      },
      {
        name: "工业与民用建筑",
      },
      {
        name: "工程造价管理",
      },
      {
        name: "建筑环境与设备工程",
      },
      {
        name: "给排水科学与工程",
      },
      {
        name: "供热通风与空调工程",
      },
      {
        name: "室内装潢设计",
      },
      {
        name: "城市规划",
      },
      {
        name: "历史建筑保护工程",
      },
      {
        name: "景观学",
      },
    ],
  },
  {
    id: "11",
    className: "交通运输类",
    class: [
      {
        name: "交通运输",
      },
      {
        name: "交通工程",
      },
      {
        name: "物流工程",
      },
      {
        name: "油气储运工程",
      },
      {
        name: "轮机工程",
      },
      {
        name: "飞行技术",
      },
      {
        name: "航海技术",
      },
      {
        name: "海事管理",
      },
    ],
  },
  {
    id: "12",
    className: "武器类",
    class: [
      {
        name: "武器系统与发射工程",
      },
      {
        name: "探测制导与控制技术",
      },
      {
        name: "弹药工程与爆炸技术",
      },
      {
        name: "特种能源工程与烟火技术",
      },
      {
        name: "地面武器机动工程",
      },
      {
        name: "信息对抗技术",
      },
    ],
  },
  {
    id: "13",
    className: "天文地质地理类",
    class: [
      {
        name: "天文学",
      },
      {
        name: "地质学",
      },
      {
        name: "地球化学",
      },
      {
        name: "地球物理学",
      },
      {
        name: "地球与空间科学",
      },
      {
        name: "地理科学",
      },
      {
        name: "地理信息系统",
      },
      {
        name: "地理信息科学与技术",
      },
      {
        name: "大气科学",
      },
      {
        name: "海洋科学",
      },
      {
        name: "地矿",
      },
      {
        name: "石油工程",
      },
      {
        name: "宝石鉴定与加工",
      },
    ],
  },
  {
    id: "14",
    className: "经济学类",
    class: [
      {
        name: "经济与金融",
      },
      {
        name: "经济学",
      },
      {
        name: "财政学",
      },
      {
        name: "金融学",
      },
      {
        name: "经济管理",
      },
      {
        name: "经济信息管理",
      },
      {
        name: "国际经济与贸易",
      },
      {
        name: "国际金融",
      },
      {
        name: "贸易经济",
      },
      {
        name: "金融工程",
      },
      {
        name: "投资学",
      },
      {
        name: "统计学",
      },
      {
        name: "保险",
      },
      {
        name: "税务",
      },
      {
        name: "信用管理",
      },
      {
        name: "网络经济学",
      },
      {
        name: "技术经济",
      },
    ],
  },
  {
    id: "15",
    className: "语言文学类",
    class: [
      {
        name: "汉语言文学",
      },
      {
        name: "对外汉语",
      },
      {
        name: "英语",
      },
      {
        name: "商务英语",
      },
      {
        name: "外贸英语",
      },
      {
        name: "俄语",
      },
      {
        name: "德语",
      },
      {
        name: "法语",
      },
      {
        name: "西班牙语",
      },
      {
        name: "阿拉伯语",
      },
      {
        name: "日语",
      },
      {
        name: "朝鲜语",
      },
      {
        name: "其它外语",
      },
    ],
  },
  {
    id: "16",
    className: "艺术类",
    class: [
      {
        name: "音乐、作曲",
      },
      {
        name: "舞蹈",
      },
      {
        name: "美术学",
      },
      {
        name: "艺术设计",
      },
      {
        name: "绘画",
      },
      {
        name: "雕塑",
      },
      {
        name: "服装设计",
      },
      {
        name: "影视学",
      },
      {
        name: "导演、广播电视编导",
      },
      {
        name: "戏剧、表演",
      },
      {
        name: "戏剧影视文学",
      },
      {
        name: "戏剧影视美术设计",
      },
      {
        name: "摄影、动画",
      },
      {
        name: "播音、主持、录音",
      },
    ],
  },
  {
    id: "17",
    className: "法学类",
    class: [
      {
        name: "法学",
      },
      {
        name: "马克思主义理论",
      },
      {
        name: "政治学与行政学",
      },
      {
        name: "思想政治教育",
      },
      {
        name: "国际政治",
      },
      {
        name: "国际经济法",
      },
      {
        name: "经济法",
      },
      {
        name: "社会学",
      },
      {
        name: "外交学",
      },
      {
        name: "公安学",
      },
    ],
  },
  {
    id: "18",
    className: "医学类",
    class: [
      {
        name: "基础医学",
      },
      {
        name: "预防医学",
      },
      {
        name: "营养学",
      },
      {
        name: "临床医学与医学技术",
      },
      {
        name: "麻醉学",
      },
      {
        name: "放射医学",
      },
      {
        name: "眼视光学",
      },
      {
        name: "口腔医学",
      },
      {
        name: "中医学",
      },
      {
        name: "针灸推拿学",
      },
      {
        name: "法医学",
      },
      {
        name: "护理学",
      },
      {
        name: "药学",
      },
      {
        name: "中药学",
      },
      {
        name: "医学检验",
      },
    ],
  },
  {
    id: "19",
    className: "农业类",
    class: [
      {
        name: "农学",
      },
      {
        name: "林学",
      },
      {
        name: "农业工程",
      },
      {
        name: "森林资源",
      },
      {
        name: "园林",
      },
      {
        name: "园艺",
      },
      {
        name: "林业工程",
      },
      {
        name: "植物生产",
      },
      {
        name: "植物保护学",
      },
      {
        name: "茶学",
      },
      {
        name: "草业科学",
      },
      {
        name: "环境生态",
      },
      {
        name: "动物科学",
      },
      {
        name: "动物医学",
      },
      {
        name: "水产科学",
      },
    ],
  },
  {
    id: "20",
    className: "电气信息类",
    class: [
      {
        name: "电气工程及其自动化",
      },
      {
        name: "电气信息工程",
      },
      {
        name: "通信工程",
      },
      {
        name: "自动化",
      },
      {
        name: "电子信息工程",
      },
      {
        name: "电子科学与技术",
      },
      {
        name: "集成电路设计与集成系统",
      },
      {
        name: "影视艺术技术",
      },
      {
        name: "广播电视工程",
      },
    ],
  },
  {
    id: "21",
    className: "计算机科学与技术类",
    class: [
      {
        name: "计算机科学与技术",
      },
      {
        name: "计算机科学",
      },
      {
        name: "计算机工程",
      },
      {
        name: "计算机网络",
      },
      {
        name: "计算机应用",
      },
      {
        name: "软件工程",
      },
      {
        name: "计算机信息管理",
      },
    ],
  },
  {
    id: "22",
    className: "新闻传播学类",
    class: [
      {
        name: "新闻学",
      },
      {
        name: "广播电视新闻",
      },
      {
        name: "广告学",
      },
      {
        name: "编辑出版学",
      },
      {
        name: "传播学",
      },
      {
        name: "媒体创意",
      },
    ],
  },
  {
    id: "23",
    className: "系统科学类",
    class: [
      {
        name: "系统理论",
      },
      {
        name: "系统科学与工程",
      },
    ],
  },
  {
    id: "24",
    className: "历史学类",
    class: [
      {
        name: "历史学",
      },
      {
        name: "考古学",
      },
      {
        name: "博物馆学",
      },
    ],
  },
  {
    id: "25",
    className: "教育学类",
    class: [
      {
        name: "教育学",
      },
      {
        name: "体育教育",
      },
      {
        name: "学前教育",
      },
      {
        name: "职业技术教育",
      },
      {
        name: "特殊教育",
      },
      {
        name: "教育技术学",
      },
    ],
  },
  {
    id: "26",
    className: "心理学类",
    class: [
      {
        name: "心理学",
      },
      {
        name: "应用心理学",
      },
    ],
  },
  {
    id: "27",
    className: "化学类及化学工程类",
    class: [
      {
        name: "化学",
      },
      {
        name: "应用化学",
      },
      {
        name: "生物化工",
      },
      {
        name: "化学工程与工艺",
      },
      {
        name: "精细化工",
      },
      {
        name: "化工设备与机械",
      },
      {
        name: "生物科学及生物技术类",
      },
    ],
  },
  {
    id: "28",
    className: "力学类",
    class: [
      {
        name: "力学",
      },
      {
        name: "应用力学",
      },
    ],
  },
  {
    id: "29",
    className: "环境科学与安全类",
    class: [
      {
        name: "环境科学",
      },
      {
        name: "环境工程",
      },
      {
        name: "安全工程",
      },
      {
        name: "生态学",
      },
    ],
  },
  {
    id: "30",
    className: "航空航天类",
    class: [
      {
        name: "飞行器设计与工程",
      },
      {
        name: "飞行器动力工程",
      },
      {
        name: "飞行器制造工程",
      },
      {
        name: "飞行器环境与生命保障工程",
      },
    ],
  },
  {
    id: "31",
    className: "水利类、船舶与海洋类",
    class: [
      {
        name: "船舶与海洋工程",
      },
      {
        name: "水利水电工程",
      },
      {
        name: "水文与水资源工程",
      },
      {
        name: "港口航道与海岸工程",
      },
    ],
  },
  {
    id: "32",
    className: "测绘类",
    class: [
      {
        name: "测绘工程",
      },
      {
        name: "遥感科学与技术",
      },
      {
        name: "空间信息与数字技术",
      },
    ],
  },
  {
    id: "33",
    className: "生物医学、制药工程类",
    class: [
      {
        name: "生物医学工程",
      },
      {
        name: "制药工程",
      },
    ],
  },
  {
    id: "",
    className: "数学类",
    class: [
      {
        name: "数学与应用数学",
      },
      {
        name: "信息与计算科学",
      },
    ],
  },
  {
    id: "35",
    className: "公安技术类",
    class: [
      {
        name: "公安技术",
      },
    ],
  },
  {
    id: "36",
    className: "物理学类",
    class: [
      {
        name: "物理学",
      },
      {
        name: "应用物理学",
      },
      {
        name: "声学",
      },
    ],
  },
  {
    id: "37",
    className: "生物科学，技术",
    class: [
      {
        name: "生物工程",
      },
      {
        name: "生物信息学",
      },
    ],
  },
  {
    id: "38",
    className: "哲学类",
    class: [
      {
        name: "哲学(含伦理学)",
      },
      {
        name: "逻辑学",
      },
      {
        name: "宗教学",
      },
    ],
  },
];
function resetData(item) {
  const name = item.className || item.name;
  item.value = name;
  item.label = name;
  return item;
}
export const majorListData = majorList.map((item) => {
  resetData(item);
  if (item.class) {
    item.children = item.class.map(resetData);
  }
  return item;
});
