<template>
  <div>
    <div class="anwser student-wrapper">
      <div :class="[isPc ? 'anwser-pc' : 'anwser-m']">
        <div style="position: relative" :class="[ isPc ? 'tabletopcontain' : '']">
          <img v-if="isPc" class="tabletop" src="../assets/img/anwser/bg-pc.png" alt="" />
          <img v-else class="tabletop" src="../assets/img/collect/bg-m.jpg" alt="" />
        </div>

        <div class="teacherbox">
          <!-- <img v-if="isPc" class="imghead" src="../assets/img/collect/imghead.png" alt="" />
          <label-msg :isPc="isPc"></label-msg> -->
        </div>

        <el-form v-model="form" class="tablebox" label-position="top">
          <div class="main">
            <el-form-item prop="phoneNum">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('phoneNum')" class="required">*</span>报名手机号</span>
              <el-input
                placeholder="请输入报名手机号"
                v-model="form.phoneNum"
                maxlength="11"
                show-word-limit
                @blur="handelCheckName"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="name">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('name')" class="required">*</span>姓名</span>
              <el-input
                placeholder="请输入姓名"
                v-model="form.name"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="sex">
              <p class="question"><span v-if="formRequiredArr.includes('sex')" class="required">*</span>性别</p>
              <el-radio-group v-model="form.sex" class="d-flex">
                <el-radio v-for="item in ['男', '女']" :key="item" :label="item">{{ item }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="age">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('age')" class="required">*</span>年龄</span>
              <el-input-number
                placeholder="请输入年龄"
                v-model="form.age"
                :controls="false"
                :min="18"
                :max="40"
                class="d-flex text-left w-100"
              >
              </el-input-number>
            </el-form-item>
            <el-form-item prop="province">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('province')" class="required">*</span>户籍省份</span>
              <el-select v-model="form.province" placeholder="请选择" class="d-flex">
                <el-option
                  v-for="item in optionsProvince"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="wechatName">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('wechatName')" class="required">*</span>微信号</span>
              <el-input
                placeholder="请输入微信号"
                v-model="form.wechatName"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="emContactName">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('emContactName')" class="required">*</span>紧急联系人</span>
              <el-input
                placeholder="请输入紧急联系人"
                v-model="form.emContactName"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="otherPhoneNum">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('otherPhoneNum')" class="required">*</span>紧急联系号码（家人）</span>
              <el-input
                placeholder="请输入紧急联系号码"
                v-model="form.otherPhoneNum"
                maxlength="11"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="idNum">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('idNum')" class="required">*</span>身份证号（登记信息用保密）</span>
              <el-input
                placeholder="请输入身份证号"
                v-model="form.idNum"
                maxlength="18"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="email">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('email')" class="required">*</span>邮箱（入学通知书用）</span>
              <el-input
                placeholder="请输入邮箱"
                v-model="form.email"
              >
              </el-input>
            </el-form-item>
            <!-- <el-form-item prop="examType">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('examType')" class="required">*</span>全职/在职备考</span>
              <el-radio-group v-model="form.examType" class="d-flex">
                <el-radio v-for="item in optionsExamType" :key="item" :label="item">{{ item }}</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item prop="health">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('health')" class="required">*</span>健康状况</span>
              <el-select v-model="form.health" placeholder="请选择健康状况" class="d-flex">
                <el-option
                  v-for="item in optionsHealth"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="addressPhone">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('addressPhone')" class="required">*</span>收货电话</span>
              <el-input
                placeholder="请输入收货电话（要邮寄材料 必须填写）"
                v-model="form.addressPhone"
                maxlength="11"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="address">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('address')" class="required">*</span>学员详细地址</span>
              <el-input
                placeholder="请输入学员详细地址（要邮寄材料 必须填写）"
                v-model="form.address"
                type="textarea"
                :rows="2"
              >
              </el-input>
            </el-form-item>
            <!-- 征信状态 -->
            <!-- <el-form-item prop="creditInfo">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('creditInfo')" class="required">*</span>征信状态</span>
              <el-input
                placeholder="请选择征信状态"
                v-model="form.creditInfo"
              />
            </el-form-item> -->
            <!-- <el-form-item prop="contactSignState">
              <p class="question"><span v-if="formRequiredArr.includes('contactSignState')" class="required">*</span>合同是否已签署</p>
              <el-radio-group v-model="form.contactSignState" class="d-flex">
                <el-radio v-for="item in optionscontactSignState" :key="item" :label="item">{{ item }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="payMoney">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('payMoney')" class="required">*</span>报名学费</span>
              <el-input
                placeholder="请输入报名手机号"
                v-model="form.payMoney"
              >
              </el-input>
            </el-form-item> -->
            <el-form-item prop="parpareType">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('parpareType')" class="required">*</span>备考状态</span>
              <el-select v-model="form.parpareType" placeholder="请选择备考状态" class="d-flex">
                <el-option
                  v-for="item in optionsParpareType"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="eduLevel">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('eduLevel')" class="required">*</span>学历</span>
              <el-select v-model="form.eduLevel" placeholder="请选择学历" class="d-flex">
                <el-option
                  v-for="item in optionsEduLevel"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="eduTypeArr">
              <span slot="label" class="question"><span v-if="formRequiredArr.includes('eduType')" class="required">*</span>所学专业</span>
              <el-cascader
                v-model="form.eduTypeArr"
                :options="majorListData"
                class="d-flex"
                :props="{ checkStrictly: true }"
                @change="handleChangeEduType"></el-cascader>
            </el-form-item>
            <el-form-item prop="signTypeArr">
                <span slot="label" class="question"><span v-if="formRequiredArr.includes('signType')" class="required">*</span>报考方向（多选）</span>
                <el-select v-model="form.signTypeArr" multiple placeholder="请选择报考方向" class="d-flex">
                  <el-option
                    v-for="item in optionsSignType"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            <el-form-item prop="isSign">
              <p class="question"><span v-if="formRequiredArr.includes('isSign')" class="required">*</span>近期是否有报考</p>
              <el-radio-group v-model="form.isSign" class="d-flex">
                <el-radio v-for="item in ['是', '否']" :key="item" :label="item">{{ item }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="form.isSign === '是'">
              <el-form-item prop="signCityArr">
                <span slot="label" class="question"><span v-if="formRequiredArr.includes('signCity')" class="required">*</span>意向报考地区</span>
                <el-cascader
                  v-model="form.signCityArr"
                  :options="optionsCityTree"
                  class="d-flex"
                  @change="handleChangeSignCity"></el-cascader>
              </el-form-item>
              <el-form-item prop="examTime">
                <p class="question"><span v-if="formRequiredArr.includes('examTime')" class="required">*</span>考试时间</p>
                <el-date-picker
                  v-model="form.examTime"
                  type="date"
                  placeholder="选择考试时间"
                  value-format="yyyy-MM-dd"
                  class="d-flex w-100">
                </el-date-picker>
              </el-form-item>
            </template>
            <div :class="[isPc ? 'surepost-pc' : 'surepost', {'disabled' : submitLoading}]" @click="submit">确认提交</div>
            <img
              :class="[isPc ? 'bottomimg-pc' : 'bottomimg']"
              src="../assets/img/anwser/bottom.png"
              alt=""
            />
          </div>
        </el-form>

      </div>

      <div v-if="isPc">
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="500px">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div style="padding: 20px; box-sizing: border-box" v-else>
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="100%">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                style="width: 100%"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div v-if="isPc">
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="500px">
            <div class="messageboxcontent" style="display: block">
              <img src="@/assets/img/postsuc.png" alt="postsuc" />
              <p class="sucinfo">您已成功提交表格</p>
              <p class="tip">感谢您的参与</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogPostsuc = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div v-else>
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="100%">
            <div class="messageboxcontent" style="display: block">
              <img src="@/assets/img/postsuc.png" alt="postsuc" />
              <p class="sucinfo">您已成功提交表格</p>
              <p class="tip">感谢您的参与</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogPostsuc = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ruleSelected, ruleRequired, provinceArr, convertAddressTree, majorListData } from "../const/index";
import { isMobile } from "../utils/isMobile";
import { checkName, updateStuInfo } from "../api/index";
import labelMsg from "./components/labelCollectStudentMsg"
export default {
  components: {
    labelMsg,
  },
  data() {
    return {
      ruleSelected,
      ruleRequired,
      optionsProvince: provinceArr,
      optionsHealth: ['健康', '慢性疾病', '其它'],
      optionsSignType: ['考国公', '考省公', '三支一扶', '军队文职', '考编A', '考编B', '考编C', '考编D', '考编E', '其他'],
      optionsExamType: ['全职', '在职'],
      optionsParpareType: ['待业', '在校', '在职'],
      optionsEduLevel: ['本科', '大专', '非全日制本科', '非全日制大专', '研究生', '博士', '大专以下'],
      optionscontactSignState: ['未签署', '已签署'],
      optionsCityTree: [],
      majorListData,
      form: {
        name: '',
        sex: '',
        age: undefined,
        phoneNum: '',
        province: '',
        wechatName: '',
        emContactName: '',
        otherPhoneNum: '',
        idNum: '',
        email: '',
        health: '',
        addressPhone: '',
        address: '',
        // creditInfo: '',
        signType: '',
        signTypeArr: [],
        signCity: '',
        signCityArr: [],
        // examType: '',
        // contactSignState: '',
        // payMoney: '',
        parpareType: '',
        eduLevel: '',
        eduType: '',
        eduTypeArr: [],
        isSign: '',
        examTime: ''
      },
      formLabel: {
        name: '姓名',
        sex: '性别',
        age: '年龄',
        phoneNum: '电话',
        province: '户籍省份',
        wechatName: '微信号',
        emContactName: '紧急联系人',
        otherPhoneNum: '紧急联系号码',
        idNum: '身份证号',
        email: '邮箱',
        health: '健康状况',
        addressPhone: '学员收货电话',
        address: '学员收货详细地址',
        creditInfo: '征信状态',
        signType: '报考方向',
        signCity: '意向报考地区',
        // examType: '全职/在职备考',
        contactSignState: '合同是否已签署',
        payMoney: '报名学费',
        parpareType: '备考状态',
        eduLevel: '学历',
        eduType: '所学专业',
        isSign: '近期是否有报考',
        examTime: '考试时间'
      },
      formRequiredArr: ['name', 'sex', 'age', 'phoneNum', 'province', 'wechatName', 'idNum', 'email', 'signType', 'signCity', 'parpareType', 'eduLevel', 'isSign'],
      isPc: 1,
      hasPhoneNum: false,
      submitLoading: false,
      dialogVisible: false,
      errorinfo: "",
      dialogPostsuc: false,
    };
  },
  created() {
    if (isMobile()) {
      this.isPc = 0;
    } else {
      this.isPc = 1;
    }
    // this.setPageTitle()
    this.optionsCityTree = convertAddressTree()
    // this.getData();
  },
  mounted() {
    // this.getfontsize();
    if (!this.isPc) {
      this.setTableBox();
    }
  },
  methods: {
    /* 获取html元素下的css属性 */
    /*
      注意，我们在设置html style的fontsize后，需要给全局 p div等常用标签 加上一个默认的fontsize，以免
    */
    getfontsize() {
      const htmldom = document.querySelector("html");
      htmldom.setAttribute("style", "font-size:10px");
    },
    /*  */
    setTableBox() {
      const dom = document.querySelector(".tablebox");
      const domt = document.querySelector(".teacherbox");
      const base = 750;
      const now = document.documentElement.clientWidth;
      let width = 708.5 * (now / base) + "px";
      domt.setAttribute("style", `width:${width}`);
      dom.setAttribute("style", `width:${width}`);
    },
    async handelCheckName() {
      if (!this.validateNumber(this.form.phoneNum)) {
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
        return false;
      }
      const errorFn = () => {
        this.hasPhoneNum = false
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
      }
      try {
        const res = await checkName({ phoneNum: this.form.phoneNum })
        if (res.code == 1) {
          errorFn()
        } else {
          this.hasPhoneNum = true
        }
      } catch (error) {
        errorFn()
      }
    },
    handleChangeSignCity(val) {
      console.log(val);
    },
    handleChangeEduType(val) {
      console.log(val);
    },
    validateNumber(number) {
      var regExp = /^\d{11}$/; // 只能包含十一位数字
      if (regExp.test(number)) {
        return true;
      } else {
        return false;
      }
    },
    async submit() {
      if (this.submitLoading) return
      if (!this.hasPhoneNum) {
        this.errorinfo = "请输入您报名的手机号";
        this.dialogVisible = true;
        return false;
      }
      const Form = Object.assign({}, this.form)
      Form.signType = Form.signTypeArr.join(',')
      delete Form.signTypeArr;
      Form.signCity = Form.signCityArr.join('-')
      delete Form.signCityArr;
      Form.eduType = Form.eduTypeArr.length == 1 ? Form.eduTypeArr[1] : Form.eduTypeArr[0]
      delete Form.eduTypeArr;
      Form.address = Form.addressPhone + ' ' + Form.address
      delete Form.addressPhone
      const that = this
      const errFn = (name, text) => {
        console.log(that.formLabel[name], name);
        this.errorinfo = text || `请输入${that.formLabel[name]}`;
        this.dialogVisible = true;
      }
      for(let key of this.formRequiredArr) {
        if (['signCity', 'examTime'].includes(key)) {
          if (Form.isSign == '是' && !Form[key]) {
            errFn(key)
            return false
          }
        } else if (key == 'email') {
          if (!this.validateEmail(Form.email)) {
            errFn(key, '请输入正确的邮箱')
            return false
          }
        } else {
          if (!Form[key]) {
            errFn(key)
            return false
          }
        }
      }
      this.submitLoading = true
      let res = null;
      console.log(Form);
      try {
        // wxId=xx&chatWxId=xx 获取 url 参数
        const { query } = this.$route
        if (query) {
          Form.wxId = query.wxId;
          Form.chatWxId = query.chatWxId;
        }
        res = await updateStuInfo(Form);
        // if (res.code == 0 || res.code == 1) {
        if (res.code == 0) {
          this.dialogPostsuc = true;
          // this.$message.success("提交成功");
        } else {
          this.$message.success(res.errMsg);
        }
      } catch (error) {
        this.$message.error("提交失败");
      } finally {
        this.submitLoading = false
      }
    },
    /* 号码校验 */
    vaildPhone() {
      const tel = Number(this.inputphone);
      const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      const result = re.test(tel);
      if (!result) {
        console.log("请输入正确的电话格式");
        return false;
      } else {
        return true;
      }
    },
    // 邮箱的验证：
    validateEmail(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 定义邮箱格式的正则表达式
      return re.test(email); // 返回true或false，判断输入的字符串是否为有效的邮箱地址
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./answer.less");
.anwser-m .teacherbox {
  border-top: 0;
}
.numberinuput {
  display: flex;
  align-items: center;
  margin-top: 38px;
  font-size: 20px;
  font-weight: 600;
}
.mg-t2 {
  margin-top: 20px;
}
.text-tip {
  text-align: left;
}
.bottomimg {
  right: 0;
  bottom: 0;
}
.required {
  color: red;
  margin-right: 3px;
}
</style>
<style lang="less">
.student-wrapper {
  .el-form-item__label {
    width: 100%;
  }
}
</style>
