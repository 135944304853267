import request from '@/utils/request'

//
export function getDetail(data) {
  return request({
    url: '/dd/rest/courseSurvey/detail',
    method: 'post',
    data
  })
}
export function createSurvey(data) {
  return request({
    url: '/dd/rest/surveyRecord/create',
    method: 'post',
    data
  })
}
export function checkName(data) {
  return request({
    url: '/dd/rest/studentData/checkName',
    method: 'post',
    data
  })
}
export function updateAddress(data) {
  return request({
    url: '/dd/rest/student/updateAddress',
    method: 'post',
    data
  })
}
export function updateStuInfo(data) {
  return request({
    url: '/dd/rest/studentData/updateStuInfo',
    method: 'post',
    data
  })
}

export function getCourseDetail(data) {
  return request({
    url: '/dd/rest/courseDetail/detail',
    method: 'post',
    data
  })
}

export function getQuestionList(data) {
  return request({
    url: '/dd/rest/studentData/ruxueTest',
    method: 'post',
    data
  })
}

export function submitAnswer(data) {
  return request({
    url: '/dd/rest/studentData/ruxueAnswer',
    method: 'post',
    data
  })
}