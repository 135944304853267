<template>
  <div v-html="msg"></div>
</template>

<script>
import { getCourseDetail } from "../api/index";
export default {
    data() {
        return {
            msg: null,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            const id = this.$route.params.id;
            try {
                const res = await getCourseDetail({
                    id
                })
                console.log(res);
                document.title = res.title
                if (res) {
                    this.msg = res.contentDetail
                }
            } catch (error) {
                
            }
        }
    }
}
</script>

<style>

</style>