<template>
  <div>
    <div class="anwser">
      <div v-if="isPc" class="anwser-pc">
        <div style="position: relative" class="tabletopcontain">
          <img class="tabletop" src="../assets/img/anwser/bg-pc.png" alt="" />
        </div>

        <div class="teacherbox">
          <img class="imghead" src="../assets/img/anwser/imghead.png" alt="" />
          <!-- <div class="infobox">
            <p class="teachernav">受评人信息</p>
            <div class="line">
              <p>授课老师：</p>
              <p>{{ msg.teacherInfo }}</p>
            </div>

            <div class="line">
              <p>授课天数：</p>
              <p>{{ msg.days }}天</p>
            </div>

            <div class="line" style="border: none">
              <p>课程内容：</p>
              <p>{{ msg.courseInfo }}</p>
            </div>
          </div> -->
          <label-msg :serveyType="serveyType" :isPc="isPc" :msg="msg"></label-msg>
        </div>

        <div class="tablebox">
          <div class="main">
            <div v-if="serveyType == 2">
              <p class="question">1、班主任</p>
              <el-input
                type="text"
                placeholder="请输入班主任"
                v-model.trim="teacherInfo"
              >
              </el-input>
            </div>
            <div>
              <p class="text-tip mg-t2">
                为了更好的为您提供服务，请留下您的手机号和改进建议
              </p>
              <p class="question">{{ serveyType == 1 ? 1 : 2}}、手机号</p>
              <el-input
                placeholder="请输入手机号"
                v-model="phoneNum"
                maxlength="11"
                show-word-limit
              >
              </el-input>
            </div>
            <questionpc
              v-for="(item, index) in useranswertable"
              :key="index"
              :data="{ ...item, index: serveyType == 1 ? index + 1 : index + 2 }"
              @change="handleChange"
            ></questionpc>
            <div>
              <p class="question">{{ useranswertable.length + (serveyType == 1 ? 2 : 3) }}、改进建议</p>
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 4 }"
                placeholder="请输入内容..."
                v-model="textarea2"
              >
              </el-input>
            </div>

            <div class="surepost-pc" :class="{'disabled' : submitLoading}" @click="submit">确认提交</div>
            <img
              class="bottomimg-pc"
              src="../assets/img/anwser/bottom.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div v-else class="anwser-m">
        <div style="position: relative">
          <img class="tabletop" src="../assets/img/anwser/bg-m.jpg" alt="" />
        </div>

        <div class="teacherbox">
          <label-msg :serveyType="serveyType" :isPc="isPc" :msg="msg"></label-msg>
          <!-- <div class="infobox-m">
            <p class="teachernav-m">受评人信息</p>
            <div class="line-m">
              <p>授课老师：</p>
              <p>{{ msg.teacherInfo }}</p>
            </div>

            <div class="line-m">
              <p>授课天数：</p>
              <p>{{ msg.days }}天</p>
            </div>

            <div class="line-m" style="border: none">
              <p>课程内容：</p>
              <p>{{ msg.courseInfo }}</p>
            </div>
          </div> -->
        </div>

        <div class="tablebox">
          <div v-if="serveyType == 2">
              <p class="question">1、班主任</p>
              <el-input
                type="text"
                placeholder="请输入班主任"
                v-model.trim="teacherInfo"
              >
              </el-input>
            </div>
          <div>
            <p class="text-tip mg-t2">
              为了更好的为您提供服务，请留下您的手机号和改进建议
            </p>
            <p class="question">{{ serveyType == 1 ? 1 : 2}}、手机号</p>
            <el-input
              placeholder="请输入手机号"
              v-model="phoneNum"
              maxlength="11"
              show-word-limit
            >
            </el-input>
          </div>
          <question
            v-for="(item, index) in useranswertable"
            :key="index"
            :data="{ ...item, index: serveyType == 1 ? index + 1 : index + 2 }"
            @change="handleChange"
          ></question>
          <div>
            <p class="question">{{ useranswertable.length + (serveyType == 1 ? 2 : 3) }}、改进建议</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 4 }"
              placeholder="请输入内容..."
              v-model="textarea2"
            >
            </el-input>
          </div>

          <div class="surepost" :class="{'disabled' : submitLoading}" @click="submit">确认提交</div>
        </div>

        <img class="bottomimg" src="../assets/img/anwser/bottom.png" alt="" />
      </div>

      <div v-if="isPc">
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="500px">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div style="padding: 20px; box-sizing: border-box" v-else>
        <div class="messagebox">
          <el-dialog :visible.sync="dialogVisible" width="100%">
            <div slot="title" class="messageboxtitle">
              <img src="@/assets/img/info.png" alt="info" style="width: 20px" />
              <span class="errorfont">错误提示</span>
            </div>

            <div class="messageboxcontent">
              <img
                src="@/assets/img/error.png"
                alt="info"
                style="width: 24px"
              />
              <p class="errorinfo">{{ errorinfo }}</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                style="width: 100%"
                type="primary"
                @click="dialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div v-if="isPc">
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="500px">
            <div class="messageboxcontent" style="display: block">
              <img src="@/assets/img/postsuc.png" alt="postsuc" />
              <p class="sucinfo">您已成功提交表格</p>
              <p class="tip">感谢您的参与</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogPostsuc = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div v-else>
        <div class="postsucbox">
          <el-dialog :visible.sync="dialogPostsuc" width="100%">
            <div class="messageboxcontent" style="display: block">
              <img src="@/assets/img/postsuc.png" alt="postsuc" />
              <p class="sucinfo">您已成功提交表格</p>
              <p class="tip">感谢您的参与</p>
            </div>

            <div slot="footer" class="dialog-footer">
              <el-button
                class="footerbtn"
                type="primary"
                @click="dialogPostsuc = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import question from "../components/question.vue";
import questionpc from "../components/question-pc.vue";
import { ruleSelected, ruleRequired, keys } from "../const/index";
import { isMobile } from "../utils/isMobile";
import { getDetail, createSurvey } from "../api/index";
import labelMsg from "./components/labelMsg"
export default {
  components: {
    question,
    questionpc,
    labelMsg,
  },
  data() {
    return {
      ruleSelected,
      ruleRequired,
      useranswertable: [],
      serveyType: 1,
      teacherInfo: null,
      phoneNum: "",
      textarea2: "",
      isPc: 1,
      id: null,
      msg: {
        teacherInfo: "",
        days: 0,
        courseInfo: "",
      },
      result: [],
      submitLoading: false,
      keys,
      dialogVisible: false,
      errorinfo: "",
      dialogPostsuc: false,
      inputphone: "",
    };
  },
  created() {
    if (isMobile()) {
      this.isPc = 0;
    } else {
      this.isPc = 1;
    }
    this.getData();
  },
  mounted() {
    // this.getfontsize();
    if (!this.isPc) {
      this.setTableBox();
    }
  },
  methods: {
    async getData() {
      let res = null;
      const code = this.$route.params.id;
      try {
        res = await getDetail({ serveyCode: code });
        this.id = Number(res.id);
        this.serveyType = Number(res.serveyType);
        Object.keys(this.msg).forEach((name) => {
          this.$set(this.msg, name, res[name]);
        });
        this.setPageTitle()
        const questionList = JSON.parse(res.questionList);
        this.useranswertable = questionList.map((item) => {
          try {
            item.answerList = item.answerList ? JSON.parse(item.answerList) : [];
          } catch (error) {
            item.answerList = item.answerList ? item.answerList : [];
          }
          return item;
        });
        this.result = Array.from(
          Array(this.useranswertable.length),
          (v, k) => null
        );
        console.log(this.useranswertable);
      } catch (error) {
        console.log('getData', error);
      }
    },
    handleChange(result) {
      // index =  动态题目编号 - 前面的固定题目数量
      if (this.serveyType == 2) {
        result.key = result.key - 2
      } else {
        result.key = result.key - 1
      }
      this.result[result.key] = result.value;
    },
    /* 获取html元素下的css属性 */
    /*
      注意，我们在设置html style的fontsize后，需要给全局 p div等常用标签 加上一个默认的fontsize，以免
    */
    getfontsize() {
      const htmldom = document.querySelector("html");
      htmldom.setAttribute("style", "font-size:10px");
    },
    /*  */
    setTableBox() {
      const dom = document.querySelector(".tablebox");
      const domt = document.querySelector(".teacherbox");
      const base = 750;
      const now = document.documentElement.clientWidth;
      let width = 708.5 * (now / base) + "px";
      domt.setAttribute("style", `width:${width}`);
      dom.setAttribute("style", `width:${width}`);
    },
    validateNumber(number) {
      var regExp = /^\d{11}$/; // 只能包含十一位数字
      if (regExp.test(number)) {
        return true;
      } else {
        return false;
      }
    },
    async submit() {
      if (this.submitLoading) return
      if (this.serveyType == 2 && !this.teacherInfo) {
        this.errorinfo = " 请输入班主任~";
        this.dialogVisible = true;
        return false;
      }
      if (!this.validateNumber(this.phoneNum)) {
        this.errorinfo = "请留下您的手机号~";
        this.dialogVisible = true;
        return false;
      }
      const emptyArr = this.result.filter((item) => {
        return !Boolean(item) && item !== 0;
      });
      if (emptyArr.length > 0) {
        // this.$message.warning('还有问题未填～')

        this.errorinfo = "还有问题未填～";
        this.dialogVisible = true;
        return false;
      }
      if (!Boolean(this.textarea2)) {
        // this.$message.warning("请填写改进建议内容～");

        this.errorinfo = "请填写改进建议内容～";
        this.dialogVisible = true;
        return false;
      }
      // if (!this.vaildPhone()) {
      //   this.errorinfo = "请输入正确格式的号码";
      //   this.dialogVisible = true;
      //   return false;
      // }
      this.submitLoading = true
      const answerList = this.result.map((item, i) => {
        return {
          questionId: i + 1,
          questionInfo: this.useranswertable[i].questionInfo,
          answerChoice: this.keys[item],
          answerInfo: this.useranswertable[i].answerList[item],
        };
      });
      const form = {
        serveyId: this.id,
        answerList: JSON.stringify(answerList),
        advice: this.textarea2,
        phoneNum: this.phoneNum,
      };
      if (this.serveyType == 2) {
        form.teacherInfo = this.teacherInfo
      }
      let res = null;
      try {
        res = await createSurvey(form);
        // if (res.code == 0 || res.code == 1) {
        if (res.code == 0) {
          this.dialogPostsuc = true;
          // this.$message.success("提交成功");
        } else {
          this.$message.success(res.errMsg);
        }
      } catch (error) {
        this.$message.error("提交失败");
      } finally {
        this.submitLoading = false
      }
    },
    /* 号码校验 */
    vaildPhone() {
      const tel = Number(this.inputphone);
      const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      const result = re.test(tel);
      if (!result) {
        console.log("请输入正确的电话格式");
        return false;
      } else {
        return true;
      }
    },
    /* 设置页面标题 */
    setPageTitle() {
      document.title = this.serveyType == 1 ? this.msg.teacherInfo + "调查问卷" : '班主任调查问卷';
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./answer.less");
.numberinuput {
  display: flex;
  align-items: center;
  margin-top: 38px;
  font-size: 20px;
  font-weight: 600;
}
.mg-t2 {
  margin-top: 20px;
}
.text-tip {
  text-align: left;
}
</style>
